import * as React from "react";

import { runTest } from "../../sdk/run-test";

import "./Domains.css";
import Results from "../Results";

const DnsLookup = () => {
  const [text, setText] = React.useState<string>("");
  const [result, setResult] = React.useState<any>(null);
  const [resultError, setResultsError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    setResultsError("");
  };

  const start = async () => {
    const domainsText = text.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");
    if (!domainsText) {
      setResultsError("Please add domains");
      return;
    }
    setLoading(true);
    const domains = domainsText.split("\n");
    setText(domains.join("\n"));

    const res = await runTest({
      testsList: ["DNSLookup"],
      DNSLookup: {
        addDomainsToResults: true,
      },
      datacenterRegions: [],
      disableAutoSave: true,
      domains,
    } as any);

    const dnsLookup = res.testResult?.dnsLookup;
    if (dnsLookup) {
      if (dnsLookup?.error) {
        setResultsError(dnsLookup.error);
      } else {
        setResult(dnsLookup as any);
      }
    } else {
      console.error(res);
      setResultsError("Could not run DNSLookup test");
    }
    setLoading(false);
  };

  const reset = () => {
    setText("");
    setResult(null);
    setResultsError("");
  };

  return (
    <>
      <section className="domains">
        <h2>Domains</h2>
        <textarea
          rows={10}
          value={text}
          onChange={onTextChange}
          placeholder="https://app.testrtc.com/&#10;https://dnslookup.testrtc.com/&#10;wss://localhost:3000,['subprotocol']"
        />
        <div className="buttons">
          <button onClick={reset} disabled={loading} className="default">
            Reset
          </button>
          <button onClick={start} disabled={loading}>
            Start
          </button>
        </div>
      </section>
      {loading && <p>Running...</p>}
      {(result?.domains?.length > 0 || resultError) && !loading && (
        <Results data={result} error={resultError} />
      )}
    </>
  );
};

export default DnsLookup;
