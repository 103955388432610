import * as React from "react";

import "./Results.css";

interface IResultsProps {
  data: any;
  error: string;
}

const Results = ({ data, error }: IResultsProps) => {
  if (error) {
    return <p className="error">{error}</p>;
  }
  return (
    <section className="results">
      <h2>
        Total: {data.totalConnections} Successful: {data.successfulConnections}
      </h2>
      <table>
        {data.domains.map((x: any) => (
          <tr key={x.domain}>
            <td>
              <p>{x.domain}</p>
            </td>
            <td>
              <p className="value">
                {x.blocked ? "Blocked" : "OK"} ({x.requestTime} secs)
              </p>
            </td>
          </tr>
        ))}
      </table>
    </section>
  );
};

export default Results;
