import React from "react";

import "./App.css";
import Domains from "./containers/Domains";
import Jumbotron from "./containers/Jumbotron";

function App() {
  return (
    <div className={"App"}>
      <Jumbotron />
      <Domains />
    </div>
  );
}

export default App;
