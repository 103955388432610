import axios from "axios";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { runAllTests } from "twillio-tests/tests";
import { ILog, ITestsResultToServer } from "twillio-tests/core/TestResults";
import { LogMessage, LogPingMessage, LogSpeedMessage } from "twillio-tests/core/TestRunInfo";

const dev = false;
const devApiKey = "PR:5e70ec69834a6c1a70379b28";

export async function runTest(config: TestConfiguration): Promise<ITestsResultToServer> {
  const finishOneTest = () => {};

  const logs: ILog[] = [];

  const logMessage: LogMessage = (message, color: any, isSystemMessage) => {
    if (!isSystemMessage) {
      logs.push({ message, color });
    }
  };

  const logPingMessage: LogPingMessage = () => {
    // console.log("PING", { pingData });
  };

  const logSpeedMessage: LogSpeedMessage = () => {
    // console.log("SPEED", { speedData });
  };

  const testResult = await runAllTests(
    logMessage,
    logPingMessage,
    logSpeedMessage,
    config,
    config.testsList || [],
    finishOneTest
  );

  // Save test results
  if (!config.disableAutoSave) {
    if (config.runType) {
      testResult.runType = config.runType;
    }
    try {
      const headers = {
        apikey: dev ? devApiKey : config.API_KEY,
      };

      await axios.post(`${config.API_URL}/`, { testResult, logs }, { headers });
    } catch (err) {
      throw new Error(
        `Tests run fine, failed to save results to ${config.API_URL} with error ${err.message}`
      );
    }
  }

  return {
    testResult,
    logs,
  };
}
