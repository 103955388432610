import * as React from "react";

import "./Jumbotron.css";

const Jumbotron = () => {
  return (
    <div className="jumbotron">
      <h1>
        <a
          href="https://testrtc.com/docs/dns-lookup-widget/"
          target="_blank"
          rel="noopener noreferrer"
        >
          qualityRTC
        </a>
        <span>DNS Lookup</span>
      </h1>
      <section>
        <p>
          To check if specific HTTPS or WSS addresses are reachable from your browser, enter the
          addresses below. This can be used to figure out what are the best addresses to provide to
          us for the DNS LOOKUP configuration of your qualityRTC landing page.
        </p>
      </section>
    </div>
  );
};

export default Jumbotron;
